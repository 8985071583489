var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    {
      attrs: {
        title:
          _vm.id === null
            ? _vm.$t("cadastrar-usuario")
            : _vm.$t("editar-usuario"),
      },
    },
    [
      _c(
        "vs-tabs",
        { attrs: { color: _vm.colorx } },
        [
          _c("vs-tab", { attrs: { label: _vm.$t("usuario") } }, [
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { label: _vm.$t("name") },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("name"),
                          expression: "errors.has('name')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("name")))]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { type: "email", label: _vm.$t("email") },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("email"),
                          expression: "errors.has('email')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("email")))]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "float-right",
                      attrs: { color: "primary", type: "border" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v(_vm._s(_vm.$t("action.cancel")))]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "float-right mr-2",
                      on: { click: _vm.create },
                    },
                    [_vm._v(_vm._s(_vm.$t("action.save")))]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm.id !== null
            ? _c("vs-tab", { attrs: { label: _vm.$t("Roles") } }, [
                _c(
                  "div",
                  {
                    staticClass: "vx-row mb-6",
                    staticStyle: { "padding-top": "30px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c(
                          "vs-button",
                          {
                            attrs: { icon: "add", type: "border" },
                            on: {
                              click: function ($event) {
                                _vm.showModal = true
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("adicionar")))]
                        ),
                        _c("vs-divider"),
                        _c("Grid", {
                          key: _vm.gridKey,
                          attrs: {
                            service: _vm.roleService,
                            route_name: "roles",
                            route_grid_path: _vm.userRolesPath,
                            route_delete_path: _vm.userRolesPath,
                            hide_edit: "true",
                            delegate: {
                              destroy: function () {
                                return _vm.destroyRoleUser
                              },
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: { title: _vm.$t("Roles"), active: _vm.showModal },
          on: {
            "update:active": function ($event) {
              _vm.showModal = $event
            },
          },
        },
        [
          _vm.showModal
            ? _c("Grid", {
                attrs: {
                  service: _vm.roleService,
                  delegate: {
                    itemSelected: function () {
                      return _vm.itemSelected
                    },
                  },
                  route_name: "/roles",
                  hide_actions: "true",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }