<template>
  <vx-card :title="id === null ? $t('cadastrar-usuario') : $t('editar-usuario')">
    <vs-tabs :color="colorx">
      <vs-tab :label="$t('usuario')">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-input class="w-full" :label="$t('name')" v-model="name" />
            <span class="text-danger text-sm" v-show="errors.has('name')">{{
              errors.first('name')
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              type="email"
              :label="$t('email')"
              v-model="email"
            />
            <span class="text-danger text-sm" v-show="errors.has('email')">{{
              errors.first('email')
            }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-button
              class="float-right"
              color="primary"
              type="border"
              @click="cancel"
              >{{ $t('action.cancel') }}</vs-button
            >
            <vs-button class="float-right mr-2" @click="create"
              >{{ $t('action.save') }}</vs-button
            >
          </div>
        </div>
      </vs-tab>
      <vs-tab :label="$t('Roles')" v-if="id !== null">
        <div class="vx-row mb-6" style="padding-top: 30px">
          <div class="vx-col w-full">
            <vs-button @click="showModal = true" icon="add" type="border"
              >{{ $t('adicionar') }}</vs-button
            >
            <vs-divider />
            <Grid
              :key="gridKey"
              :service="roleService"
              route_name="roles"
              :route_grid_path="userRolesPath"
              :route_delete_path="userRolesPath"
              hide_edit="true"
              :delegate="{destroy: () => destroyRoleUser} "
            ></Grid>
          </div>
        </div>
      </vs-tab>
    </vs-tabs>

    <vs-popup :title="$t('Roles')" :active.sync="showModal">
      <Grid
        :service="roleService"
        :delegate="{itemSelected: () => itemSelected} "
        route_name="/roles"
        v-if="showModal"
        hide_actions="true"
      ></Grid>
    </vs-popup>
  </vx-card>
</template>

<script>
import UserService from '@/services/api/UserService'
import RoleService from '@/services/api/RoleService'

export default {
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    id: null,
    name: '',
    email: '',
    showModal: false,
    roleService: null,
    gridKey: 0
  }),
  computed: {
    userRolesPath() {
      return `/user/${this.id}`
    }
  },
  methods: {
    itemSelected(role) {
      if (role) {
        this.$vs.loading()
        this.roleService.attachRoleUser(role.id, this.id).then(
          response => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Successo!',
              text: this.$t('operacao-realizada-com-sucesso'),
              color: 'success',
              iconPack: 'feather',
              position: 'top-center',
              icon: 'icon-check-circle'
            })

            this.gridKey += 1
          },
          error => {
            this.$vs.loading.close()
            this.notifyError(this.$vs, this.$t('nao-foi-possivel-incluir-este-grupo'))
          }
        )
      }
      this.showModal = false
    },
    destroyRoleUser(role) {
      this.$vs.loading()
      this.roleService.destroyRoleUser(role.id, this.id).then(
        response => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Successo!',
            text: this.$t('operacao-realizada-com-sucesso'),
            color: 'success',
            iconPack: 'feather',
            position: 'top-center',
            icon: 'icon-check-circle'
          })

          this.gridKey += 1
        },
        error => {
          this.$vs.loading.close()
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-escluir-este-grupo'))
        }
      )
    },
    create() {
      this.clearErrors(this.$validator)
      this.$vs.loading()

      const userService = UserService.build(this.$vs)
      let promise = null

      const data = {
        email: this.email,
        name: this.name
      }

      if (this.isEmpty(this.id)) {
        promise = userService.internalStore(data)
      } else {
        data['id'] = this.id
        promise = userService.update(data)
      }
      
      promise.then(
        data => {
          this.id = data.id
          this.$vs.loading.close()

          this.$vs.notify({
            title: 'Successo!',
            text: this.$t('operacao-realizada-com-sucesso'),
            color: 'success',
            iconPack: 'feather',
            position: 'top-center',
            icon: 'icon-check-circle'
          })

          this.$router.push(`/users/${data.id}/edit`)
        },
        error => {
          this.$vs.loading.close()
          this.showErrors(this.$validator, error)
        }
      )

    },
    cancel() {
      this.$router.push('/users')
    },
    getData(id) {
      this.$vs.loading()
      const service = UserService.build(this.$vs)
      service.read(id).then(
        data => {
          this.$set(this.$data, 'id', data.id)
          this.$set(this.$data, 'name', data.name)
          this.$set(this.$data, 'email', data.email)

          this.$vs.loading.close()
        },
        error => {
          this.$vs.loading.close()
        }
      )
    }
  },
  created() {
    this.roleService = RoleService.build(this.$vs)
    if (this.id) {
      this.getData(this.id)
    }
  }
}
</script>
